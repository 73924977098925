import { template as template_f8c33f4ec4b34a87803de5cd60e0342e } from "@ember/template-compiler";
const FKText = template_f8c33f4ec4b34a87803de5cd60e0342e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
